
.service-25 h1, .service-25 h2, .service-25 h3, .service-25 h4, .service-25 h5, .service-25 h6 {
  color: #3e4555;
}

.service-25 .bg-light {
    background-color: #f4f8fa !important;
}

.service-25 .subtitle {
    color: #8d97ad;
    line-height: 24px;
}

.anchor-black  {
color:black
}